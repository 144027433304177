import React from 'react';
import { Icon } from '@iconify/react';

import swiftIcon from '@iconify/icons-simple-icons/swift';
import reactIcon from '@iconify/icons-simple-icons/react';
import reduxIcon from '@iconify/icons-simple-icons/redux';
import sassIcon from '@iconify/icons-simple-icons/sass';
import javascriptIcon from '@iconify/icons-simple-icons/javascript';
import xcodeIcon from '@iconify/icons-simple-icons/xcode';

import {
    TOOLS_ID,
    LIBRARIES_ID,
    BUNDLERS_ID,
    PROGRAMMING_LANGUES_ID,
    STRONG_ID,
    KNOWLEDGEABLE_ID,
    FRAMEWORKS_ID,
    LANGUES_ID,
} from './VariablesData';

export const Bash = {
        name: 'Bash',
        skillCategoryId: TOOLS_ID,
        levelId: STRONG_ID,
    },
    OhMyZshell = {
        name: 'Oh-my-zsh',
        skillCategoryId: TOOLS_ID,
        levelId: STRONG_ID,
    },
    Bootstrap = {
        name: 'Bootstrap',
        skillCategoryId: LIBRARIES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    CocoaPods = {
        name: 'CocoaPods',
        skillCategoryId: BUNDLERS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    CSharp = {
        name: 'C#',
        skillCategoryId: PROGRAMMING_LANGUES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Git = {
        name: 'Git',
        pdfName: 'Git/Gitflow',
        skillCategoryId: BUNDLERS_ID,
        levelId: STRONG_ID,
    },
    Gitflow = {
        name: 'Gitflow',
        skillCategoryId: BUNDLERS_ID,
        levelId: STRONG_ID,
    },
    Html = {
        name: 'HTML',
        pdfName: 'HTML/Twig',
        skillCategoryId: FRAMEWORKS_ID,
        levelId: STRONG_ID,
    },
    Javascript = {
        name: 'Javascript',
        pdfName: 'ReactJs/Javascript',
        svg: <Icon icon={javascriptIcon} color='#f0db4f' />,
        skillCategoryId: PROGRAMMING_LANGUES_ID,
        levelId: STRONG_ID,
    },
    Java = {
        name: 'Java',
        skillCategoryId: PROGRAMMING_LANGUES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Laravel = {
        name: 'Laravel',
        skillCategoryId: LIBRARIES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    MySQL = {
        name: 'MySQL',
        skillCategoryId: LIBRARIES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    NodeJs = {
        name: 'Node.Js',
        skillCategoryId: BUNDLERS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Npm = { name: 'NPM', skillCategoryId: BUNDLERS_ID, levelId: STRONG_ID },
    Parse = {
        name: 'Parse',
        skillCategoryId: BUNDLERS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Pyramid = {
        name: 'Pyramid',
        pdfName: 'Pyramid/Chameleon',
        skillCategoryId: FRAMEWORKS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Chameleon = {
        name: 'Chameleon',
        skillCategoryId: FRAMEWORKS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Python = {
        name: 'Python',
        skillCategoryId: PROGRAMMING_LANGUES_ID,
        levelId: STRONG_ID,
    },
    Recompose = {
        name: 'Recompose',
        skillCategoryId: LIBRARIES_ID,
        levelId: STRONG_ID,
    },
    Redux = {
        name: 'Redux',
        pdfName: 'Redux/-saga',
        svg: <Icon icon={reduxIcon} color='#764abc' />,
        skillCategoryId: LIBRARIES_ID,
        levelId: STRONG_ID,
    },
    ReduxSaga = {
        name: 'Redux-Saga',
        skillCategoryId: LIBRARIES_ID,
        levelId: STRONG_ID,
    },
    Sass = {
        name: 'Sass',
        pdfName: 'Sass/Styled-Components',
        svg: <Icon icon={sassIcon} color='#cd6799' />,
        skillCategoryId: LIBRARIES_ID,
        levelId: STRONG_ID,
    },
    Scrum = { name: 'Scrum', skillCategoryId: FRAMEWORKS_ID, levelId: STRONG_ID },
    Sketch = { name: 'Sketch', skillCategoryId: TOOLS_ID, levelId: KNOWLEDGEABLE_ID },
    StyledComponents = {
        name: 'Styled-Components',
        skillCategoryId: LIBRARIES_ID,
        levelId: STRONG_ID,
    },
    Swift = {
        name: 'Swift',
        svg: <Icon icon={swiftIcon} color='#fd3f27' />,
        skillCategoryId: PROGRAMMING_LANGUES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Swift2 = {
        name: 'Swift 2.0',
        skillCategoryId: PROGRAMMING_LANGUES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Twig = {
        name: 'Twig',
        skillCategoryId: FRAMEWORKS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Gsap = {
        name: 'GSAP',
        skillCategoryId: LIBRARIES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    VueJs = {
        name: 'VueJs',
        skillCategoryId: FRAMEWORKS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Webpack = {
        name: 'Webpack',
        pdfName: 'Yarn/Webpack/Babel',
        skillCategoryId: BUNDLERS_ID,
        levelId: STRONG_ID,
    },
    Babel = {
        name: 'Babel',
        skillCategoryId: BUNDLERS_ID,
        levelId: STRONG_ID,
    },
    Wordpress = {
        name: 'Wordpress',
        skillCategoryId: LIBRARIES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Xcode = {
        name: 'Xcode',
        svg: <Icon icon={xcodeIcon} color='#00aeef' />,
        skillCategoryId: TOOLS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Valet = {
        name: 'Valet',
        skillCategoryId: BUNDLERS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Yarn = { name: 'Yarn', skillCategoryId: BUNDLERS_ID, levelId: STRONG_ID },
    Grunt = {
        name: 'Grunt',
        pdfName: 'Grunt/Gulp',
        skillCategoryId: BUNDLERS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Gulp = {
        name: 'Gulp',
        skillCategoryId: BUNDLERS_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Danish = { name: 'Danish', skillCategoryId: LANGUES_ID, levelId: STRONG_ID },
    English = {
        name: 'English',
        skillCategoryId: LANGUES_ID,
        levelId: STRONG_ID,
    },
    Kurdish = {
        name: 'Kurdish',
        skillCategoryId: LANGUES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Turkish = {
        name: 'Turkish',
        skillCategoryId: LANGUES_ID,
        levelId: KNOWLEDGEABLE_ID,
    },
    Docker = { name: 'Docker', skillCategoryId: TOOLS_ID, levelId: KNOWLEDGEABLE_ID },
    ReactJs = {
        name: 'ReactJs',
        svg: <Icon icon={reactIcon} color='#61dbfb' />,
        skillCategoryId: FRAMEWORKS_ID,
        levelId: STRONG_ID,
    };

export const MySkills = [
    // here Starts Programming Languages
    Javascript,
    Python,
    Swift,
    Java,
    CSharp,
    // here Starts Web Technologies
    Sass,
    Redux,
    ReduxSaga,
    Recompose,
    StyledComponents,
    Gsap,
    Bootstrap,
    MySQL,
    Laravel,
    Wordpress,
    NodeJs,
    //FRAMEWORK
    ReactJs,
    Scrum,
    VueJs,
    Pyramid,
    Chameleon,
    Html,
    Twig,
    // here Start Bundlers and others
    Webpack,
    Babel,
    Yarn,
    Npm,
    Git,
    Gitflow,
    Valet,
    Grunt,
    Gulp,
    CocoaPods,
    // here Starts Tools
    Bash,
    Docker,
    Sketch,
    Xcode,
    // here Starts Languages
    Danish,
    English,
    Kurdish,
    Turkish,
];
export const MyPDFSkills = [Javascript, Sass, Redux, Html, Git, Webpack, Python, Pyramid, VueJs, MySQL, NodeJs, Docker];
export const MyPDFLanguages = [Danish, English, Kurdish, Turkish];
