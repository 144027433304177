import React, { useContext, forwardRef } from 'react';
import styled from 'styled-components';

import { RightAlignContext } from '../../Context';
import { flexUnit } from '../Styling/Mixins';
import { respondAbove } from '../Styling/Queries';
import { outlineTitleTheme } from '../Styling/Theme';

export default forwardRef(({ title }, ref) => {
    const rightAlign = useContext(RightAlignContext);
    return <PageTitle ref={ref} rightAlign={rightAlign} dangerouslySetInnerHTML={{ __html: title }} />;
});

const PageTitle = styled.div`
    align-self: ${props => (props.rightAlign ? 'flex-end' : 'flex-start')};

    font-size: 3rem;
    ${flexUnit(10, 40, 160)}
    ${flexUnit(10, 35, 160, 'vw', 'line-height')}
    font-weight: 700;
    pointer-events: none;
    text-align: ${props => (props.rightAlign ? 'right' : 'left')};
    text-transform: uppercase;

    white-space: pre-wrap;
    width: 100%;
    ${outlineTitleTheme};
    @media ${respondAbove.sm} {
        margin: 0 auto;
        /* font-size: 8rem; */
        /* line-height: 7rem; */
    }
    @media ${respondAbove.md} {
        /* line-height: 9rem; */
        /* font-size: 9.5rem; */
        flex: 13;
    }
`;
