export const FRAMEWORKS_ID = 1;
export const PROGRAMMING_LANGUES_ID = 2;
export const LIBRARIES_ID = 3;
export const BUNDLERS_ID = 4;
export const TOOLS_ID = 5;
export const LANGUES_ID = 6;

export const STRONG_ID = 1;
export const STRONG = { id: 1, label: 'Strong' };
export const KNOWLEDGEABLE = { id: 2, label: 'Knowledgeable' };
export const KNOWLEDGEABLE_ID = 2;

export const FRONT_END_DEVELOPER = 'Front-end Developer';
export const FRONT_END_DEVELOPER_ID = 1;
export const IOS_DEVELOPER = 'iOS Swift Developer';
export const IOS_DEVELOPER_ID = 2;

export const PDF_COLORS = {
    RED_DARK: '#651D36',
    RED: '#900D3F',
    BLACK: '#111111',
};

export const COLORS = {
    BLACK: '#181818',
    YELLOW: 'rgb(235, 199, 78)',
    GOLD: '#b09759',
    WHITE: '#e7e7e7',
    MINT: '#47beae',

    //DARK
    DARK: '#181818',
    DARK_COMPLEMENTARY: 'rgb(235, 199, 78)',
    DARK_COMPLEMENTARY_2: '#47beae',
    DARK_COMPLEMENTARY_3: '#b09759',
    DARK_TEXT: '#e7e7e7',
    //MCD
    MCD: '#ffbc0f',
    MCD_COMPLEMENTARY: '#fff',
    MCD_TEXT: '#dc0005',
    //RED
    RED: '#503650',
    RED_COMPLEMENTARY: '#f21329',
    RED_TEXT: '#97f0e0',
    //BLUE
    BLUE: '#1743be',
    BLUE_COMPLEMENTARY: '#64f0dd',
    BLUE_TEXT: '#f774a5',
    //VIOLET
    VIOLET: '#bf9dcc',
    VIOLET_COMPLEMENTARY: '#fce50f',
    VIOLET_TEXT: '#2c42bc',
    //PURPLE
    PURPLE: '#011b56',
    PURPLE_COMPLEMENTARY: '#ff62ca',
    PURPLE_TEXT: '#f7c85c',
    //GREEN
    GREEN: '#006550',
    GREEN_COMPLEMENTARY: '#f8d0d3',
    GREEN_TEXT: '#f7c85c',
};
