import React, { Component } from 'react';
import styled from 'styled-components';
import { TimelineMax } from 'gsap/all';

import { COLORS } from './Data/VariablesData';
import { ThemeToggleContext, ThemeToggleConsumer } from '../ThemeContext';
import { respond } from './Styling/Queries';

const ArrayOfThemes = [
    {
        backgroundColor: COLORS.MCD,
        textColor: COLORS.MCD_COMPLEMENTARY,
        sloganColor: COLORS.MCD_TEXT,
        theme: 'mcd',
        slogan: "Are you Lovin' it?",
    },
    {
        backgroundColor: COLORS.DARK,
        textColor: COLORS.DARK_TEXT,
        sloganColor: COLORS.DARK_COMPLEMENTARY,
        theme: 'dark',
        slogan: 'Dark, Short\n& Handsome',
    },
    {
        backgroundColor: COLORS.PURPLE,
        textColor: COLORS.PURPLE_TEXT,
        sloganColor: COLORS.PURPLE_COMPLEMENTARY,
        theme: 'purple',
        slogan: 'My least favorite color is purple',
    },
    {
        backgroundColor: COLORS.GREEN,
        textColor: COLORS.GREEN_COMPLEMENTARY,
        sloganColor: COLORS.GREEN_TEXT,
        theme: 'green',
        slogan: "I'm a lover, not a fighter",
    },
    {
        backgroundColor: COLORS.BLUE,
        textColor: COLORS.BLUE_COMPLEMENTARY,
        sloganColor: COLORS.BLUE_TEXT,
        theme: 'blue',
        slogan: "Yo, listen up, here's a story",
    },
    {
        backgroundColor: COLORS.VIOLET,
        textColor: COLORS.VIOLET_TEXT,
        sloganColor: COLORS.VIOLET_COMPLEMENTARY,
        theme: 'violet',
        slogan: ' Violets are blue',
    },
];

export default class ThemeSelectorView extends Component {
    static contextType = ThemeToggleContext;

    themeViewRef;
    labelRefs = [];
    themes = [];
    theme;

    componentDidMount() {
        this.tl2 = new TimelineMax({ repeat: 0 }).fromTo(
            this.themeViewRef,
            0.5,
            {
                opacity: 1,
            },
            {
                zIndex: 111111,
                opacity: 0,
            }
        );
        this.tl = new TimelineMax({ repeat: 0, onReverseComplete: this.reverseFunction.bind(this) })
            .fromTo(
                this.themeViewRef,
                0.5,
                {
                    opacity: 0,
                    background: 'none',
                },
                {
                    opacity: 1,
                }
            )
            .staggerFromTo(
                this.themes,
                0.75,
                {
                    cycle: {
                        transform: i => (i >= ArrayOfThemes.length / 2 ? 'translateY(100vh)' : 'translateY(-100vh)'),
                    },
                    opacity: 1,
                    color: 'transparent',
                    stagger: 0,
                },
                {
                    transform: 'translateY(0%)',
                    opacity: 1,
                    stagger: 0,
                }
            )
            .staggerTo(this.themes, 0.5, {
                cycle: {
                    color: i => ArrayOfThemes[i].sloganColor,
                },

                stagger: 0.2,
            })
            .staggerFromTo(this.labelRefs, 0.75, { opacity: 0, stagger: 0 }, { opacity: 1, stagger: 0, delay: 0.15 });
    }
    hideSelector = theme => {
        this.tl.reverse();
        this.theme = theme;
    };
    reverseFunction() {
        const { toggle } = this.context;
        toggle(this.theme);
    }
    render() {
        return (
            <ThemeToggleConsumer>
                {props => (
                    <ThemeView ref={el => (this.themeViewRef = el)}>
                        {ArrayOfThemes.map(({ backgroundColor, textColor, theme, sloganColor, slogan }) => {
                            return (
                                <ThemeTeasers
                                    key={theme}
                                    onClick={() => this.hideSelector(theme)}
                                    ref={el => this.themes.push(el)}
                                    background={backgroundColor}
                                    text={sloganColor}>
                                    <ThemeSelectLabel ref={el => this.labelRefs.push(el)} color={textColor}>
                                        Select Theme
                                    </ThemeSelectLabel>

                                    {slogan}
                                </ThemeTeasers>
                            );
                        })}
                    </ThemeView>
                )}
            </ThemeToggleConsumer>
        );
    }
}

const ThemeSelectLabel = styled.div`
    color: ${props => props.color};
    flex: 1 0 100%;
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    top: 0.5rem;
    @media screen and ${respond.above.md} {
        top: 1rem;
        font-size: 2rem;
    }
`;

const ThemeView = styled.div`
    background-color: ${COLORS.DARK};
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    justify-content: start;
    left: 0;
    opacity: 0;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    transition: opacity 1s ease;
    z-index: 2;
`;
const ThemeTeasers = styled.div`
    align-items: center;
    background: ${props => props.background};
    color: ${props => props.text};
    cursor: pointer;
    display: flex;
    flex: 1 calc(100vw);
    flex-grow: 1;
    font-size: 1.3rem;
    font-weight: bold;
    justify-content: center;
    max-width: calc(100vw);
    opacity: 0;
    overflow: hidden;
    padding: 20px;
    position: relative;
    text-transform: uppercase;
    @media screen and ${respond.above.md} {
        font-size: 4rem;
        max-width: calc(100vw / 3);
        flex: 1 calc(100vw / 3);
        height: calc(100vh / 2);
    }
`;
