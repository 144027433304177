import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import '../scss/App.scss';

import AppContainer from './AppContainer';
import { mediaChange } from './actions/actions';
import ThemeSelectorView from './ThemeSelectorView';
import { ThemeToggleContext, ThemeToggleConsumer } from '../ThemeContext';
import { backgroundColor } from './Styling/Theme';

const GlobalStyle = createGlobalStyle`
 html {
    background: ${backgroundColor};
  }
`;

class App extends Component {
    static contextType = ThemeToggleContext;

    componentDidMount() {
        const { handleMediaChange } = this.props;

        const mediaQuery = window.matchMedia('(min-width: 992px)');

        handleMediaChange(mediaQuery);
        mediaQuery.addListener(() => handleMediaChange(mediaQuery));
    }

    render() {
        return (
            <HelmetProvider>
                <AppStyled>
                    <Helmet>
                        <title>Haci Kale</title>
                    </Helmet>
                    <GlobalStyle />
                    <ThemeToggleConsumer>
                        {props => {
                            if (props.showThemeSelector) {
                                return <ThemeSelectorView />;
                            }
                        }}
                    </ThemeToggleConsumer>
                    <AppContainer />
                </AppStyled>
            </HelmetProvider>
        );
    }
}

const AppStyled = styled.div`
    margin: 0 auto;
    overscroll-behavior: none;
    position: relative;
    transform: opacity 1s ease;
    width: 100%;
`;
const mapStateToProps = ({ isMobile, mediaChecked }) => ({ isMobile, mediaChecked });
const mapDispatchToProps = dispatch => ({
    handleMediaChange: mediaQuery => dispatch(mediaChange(mediaQuery)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
