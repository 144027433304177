import React, { useState } from 'react';
import styled from 'styled-components';
import { menuItemsColor, menuItemsColorHover, backgroundColor } from '../Styling/Theme';
import { useTheme } from '../../ThemeContext';
import { respond } from '../Styling/Queries';

export default ({ menuOpen }) => {
    const [state, setstate] = useState({ copied: false });
    const themeToggle = useTheme();
    const copyEmail = () => {
        setstate({ copied: true });

        //COPY Email
        navigator.clipboard.writeText('kale.hk@gmail.com');

        setTimeout(() => setstate({ copied: false }), 5000);
    };
    return (
        <Menu open={menuOpen}>
            <MenuItem copied={state.copied} onClick={() => copyEmail()}>
                {state.copied ? 'Copied!' : 'Contact'}
            </MenuItem>
            <MenuItem onClick={() => themeToggle.toggleShowThemeSelector(true)}>Change Theme</MenuItem>
            <MenuItem>
                <Link href='https://github.com/HaciKale' target='_blank'>
                    GitHub
                </Link>
            </MenuItem>

            <MenuItem>
                <Link href='https://linkedin.com/in/haci-kale/' target='_blank'>
                    linkedin
                </Link>
            </MenuItem>
        </Menu>
    );
};
const Menu = styled.div`
    background-color: ${backgroundColor};
    font-size: 1.4rem;
    height: ${props => (props.open ? '100vh' : '0')};
    overflow: hidden;
    position: absolute;
    right: 0;
    transition: all 1s ease;
    @media ${respond.above.md} {
        font-size: 2.4rem;
    }
`;
const MenuItem = styled.div`
    color: ${props => (props.copied ? menuItemsColorHover : menuItemsColor)};
    margin-left: auto;
    padding-left: 2rem;
    text-align: right;
    white-space: nowrap;
    &:hover {
        color: ${menuItemsColorHover};
        text-shadow: none;
    }
`;

const Link = styled.a`
    color: ${menuItemsColor};
    &:hover {
        color: ${menuItemsColorHover};
        text-shadow: none;
    }
`;
