import {
    Javascript,
    Python,
    Swift,
    CSharp,
    Sass,
    StyledComponents,
    Redux,
    ReduxSaga,
    Html,
    Recompose,
    Gsap,
    Bootstrap,
    MySQL,
    Laravel,
    Wordpress,
    NodeJs,
    ReactJs,
    Scrum,
    VueJs,
    Pyramid,
    Twig,
    Webpack,
    Yarn,
    Npm,
    Git,
    Valet,
    Grunt,
    CocoaPods,
    Bash,
    OhMyZshell,
    Docker,
    Sketch,
    Xcode,
    Parse,
    Swift2,
} from './Data/SkillsData';
import {
    FRAMEWORKS_ID,
    PROGRAMMING_LANGUES_ID,
    LIBRARIES_ID,
    BUNDLERS_ID,
    TOOLS_ID,
    LANGUES_ID,
    FRONT_END_DEVELOPER,
    IOS_DEVELOPER,
    IOS_DEVELOPER_ID,
    FRONT_END_DEVELOPER_ID,
} from './Data/VariablesData';

export const Education = [
    {
        name: 'KEA',
        title: 'Computer Science',
        period: '2013 - 2016',
        link: 'https://kea.dk/en/programmes/academy-profession-degree/computer-science',
    },
    {
        name: 'SESG',
        title: 'Higher Commercial\nExamination Programme',
        period: '2008 - 2011',
        link: 'https://sesg.dk/handelsgymnasiet-hhx/',
    },
];

export const roles = [
    { id: FRONT_END_DEVELOPER_ID, title: FRONT_END_DEVELOPER },
    { id: IOS_DEVELOPER_ID, title: IOS_DEVELOPER },
];

export const skillCategory = [
    { category: 'Frameworks\n& Templating', id: FRAMEWORKS_ID },
    { category: 'Programming Languages', id: PROGRAMMING_LANGUES_ID },
    { category: 'Libraries', id: LIBRARIES_ID },
    { category: 'Bundlers and others', id: BUNDLERS_ID },
    { category: 'Tools', id: TOOLS_ID },
    { category: 'Languages', id: LANGUES_ID },
];

export const jobs = [
    {
        name: 'Dwarf',
        roleId: FRONT_END_DEVELOPER_ID,
        data: {
            about:
                'Lorem Ipsum er ganske enkelt fyldtekst fra print- og typografiindustrien. Lorem Ipsum har været standard fyldtekst siden 1500-tallet, hvor en ukendt trykker sammensatte en tilfældig spalte for at trykke en bog til sammenligning af forskellige skrifttyper. Lorem Ipsum har ikke alene overlevet fem århundreder, men har også vundet indpas i elektronisk typografi uden væsentlige ændringer.',
            dates: {
                start: 'September',
                startyear: '2018',
                end: 'present',
                endyear: '',
            },
            role: FRONT_END_DEVELOPER,
            description: [
                "Lead the front-end development of the agency's second largest project to a successful launch, Increasing sales with a large percentage and adding more than 50 features across 3 applications to the client Comwell's website with ReactJs and Redux.",
                "Elevated design and implemented a paywall logic for TF Technologies' construction technology company customer-facing admin panel using Vanilla Javascript and Sass",
                'Building a multi-profile home inspections approval system for Danish Safety Technology Authority with ReactJs and Redux',
            ],

            Skills: [
                ReactJs,
                Redux,
                Sass,
                Html,
                Scrum,
                Javascript,
                Webpack,
                Yarn,
                Docker,
                Git,
                Bash,
                OhMyZshell,
                StyledComponents,
                Recompose,
                Sketch,
                Valet,
                Twig,
                Gsap,
                Grunt,
                VueJs,
                Bootstrap,
                Laravel,
                NodeJs,
            ],
        },
    },
    {
        name: 'Motosumo',
        roleId: FRONT_END_DEVELOPER_ID,
        data: {
            about:
                'Lorem Ipsum er ganske enkelt fyldtekst fra print- og typografiindustrien. Lorem Ipsum har været standard fyldtekst siden 1500-tallet, hvor en ukendt trykker sammensatte en tilfældig spalte for at trykke en bog til sammenligning af forskellige skrifttyper. Lorem Ipsum har ikke alene overlevet fem århundreder, men har også vundet indpas i elektronisk typografi uden væsentlige ændringer.',
            dates: {
                start: 'November',
                startyear: '2017',
                end: 'September',
                endyear: '2018',
            },
            role: FRONT_END_DEVELOPER,
            description: [
                'Created the Motosumo.com home-page from scratch using Python, Pyramid and Chameleon',
                'Elevated the design and added new features to "Motosumo Bigscreen" web interface for spinning classes using ReactJs and Redux',
            ],
            Skills: [
                ReactJs,
                Redux,
                ReduxSaga,
                Sass,
                Html,
                Scrum,
                Javascript,
                Python,
                Webpack,
                Yarn,
                Npm,
                Docker,
                Git,
                Bash,
                OhMyZshell,
                Pyramid,
                Bootstrap,
                MySQL,
                NodeJs,
            ],
        },
    },
    {
        name: 'Online Afdelingen',
        roleId: FRONT_END_DEVELOPER_ID,
        data: {
            about:
                'Lorem Ipsum er ganske enkelt fyldtekst fra print- og typografiindustrien. Lorem Ipsum har været standard fyldtekst siden 1500-tallet, hvor en ukendt trykker sammensatte en tilfældig spalte for at trykke en bog til sammenligning af forskellige skrifttyper. Lorem Ipsum har ikke alene overlevet fem århundreder, men har også vundet indpas i elektronisk typografi uden væsentlige ændringer.',
            dates: {
                start: 'June',
                startyear: '2017',
                end: 'October',
                endyear: '2017',
            },
            role: FRONT_END_DEVELOPER,
            description: [
                'Created several websites for sale purposes by converting Sketch design to responsive HTML and Wordpress theme.',
            ],
            Skills: [
                ReactJs,
                Sass,
                Html,
                Javascript,
                Webpack,
                Yarn,
                Npm,
                Bash,
                OhMyZshell,
                Sketch,
                Bootstrap,
                NodeJs,
                Wordpress,
            ],
        },
    },
    {
        name: 'Mustache',
        roleId: IOS_DEVELOPER_ID,
        data: {
            about:
                'Lorem Ipsum er ganske enkelt fyldtekst fra print- og typografiindustrien. Lorem Ipsum har været standard fyldtekst siden 1500-tallet, hvor en ukendt trykker sammensatte en tilfældig spalte for at trykke en bog til sammenligning af forskellige skrifttyper. Lorem Ipsum har ikke alene overlevet fem århundreder, men har også vundet indpas i elektronisk typografi uden væsentlige ændringer.',
            dates: {
                start: 'September',
                startyear: '2016',
                end: 'November',
                endyear: '2016',
            },
            role: IOS_DEVELOPER,
            description: ['Converted Sketch design to functioning iOS Mobile Application using Xcode and Swift 2.0.'],
            Skills: [Git, Bash, OhMyZshell, Sketch, Parse, Swift2, CocoaPods, Xcode],
        },
    },
    {
        name: 'Workbook',
        roleId: IOS_DEVELOPER_ID,
        data: {
            about:
                'Lorem Ipsum er ganske enkelt fyldtekst fra print- og typografiindustrien. Lorem Ipsum har været standard fyldtekst siden 1500-tallet, hvor en ukendt trykker sammensatte en tilfældig spalte for at trykke en bog til sammenligning af forskellige skrifttyper. Lorem Ipsum har ikke alene overlevet fem århundreder, men har også vundet indpas i elektronisk typografi uden væsentlige ændringer.',
            dates: {
                start: 'August',
                startyear: '2015',
                end: 'November',
                endyear: '2015',
            },
            role: IOS_DEVELOPER,
            description: [
                'Built and designed an app that allowed Workbook users to upload multiple pictures and voice recordings simultaneously to their profile whiteboard',
            ],
            Skills: [Git, Bash, OhMyZshell, MySQL, Swift, CSharp, CocoaPods, Xcode],
        },
    },
];
