import React from 'react';
import styled from 'styled-components';

import Introduction from './sections/Introduction';
import WorkExperience from './sections/WorkExperience';
import Skills from './sections/Skills';
// import Projects from './sections/Projects';
import Education from './sections/Education';
import { RightAlignProvider } from '../Context';
import { backgroundColor } from './Styling/Theme';
import { useTheme } from '../ThemeContext';
import withRef from './withRef';

const AppContainer = () => {
    const themeToggle = useTheme();

    return (
        <Container>
            <RightAlignProvider>
                <Introduction mode={themeToggle.mode} />
            </RightAlignProvider>
            <RightAlignProvider value>
                <WorkExperience />
            </RightAlignProvider>
            <RightAlignProvider>{/* <Projects /> */}</RightAlignProvider>
            <RightAlignProvider>
                <Education />
            </RightAlignProvider>
            <RightAlignProvider value>
                <Skills />
            </RightAlignProvider>
        </Container>
    );
};
export default withRef(AppContainer);

const Container = styled.div`
    background-color: ${backgroundColor};
    overflow-x: hidden;
    transition: background-color 1s;
`;
